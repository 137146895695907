import React from 'react'
import { ProjectView } from '../../components/ProjectView'
import { importAll } from '../../components/ProjectView'


const projectDetails = {
    name: 'Biglow Woodcraft',
    time: "2016 +",
    categories: ["branding", "logo design", "print materials"],
    description: 
    [   <p>In 2016 <a href="https://www.biglowwoodcraft.com">Brent Biglow</a> asked me to help him come up with a logo for his woodowrking venture. Like Brent's pieces, I wanted his logo to be sturdy and well-crafted.</p>,
        <p>We banged out the final changes over text. He really helped me nail the G. Since then, Brent has done more to make the logo look good, and I have loaned a hand as he continues to craft his brand.</p>
    ],
    images: '../img/Biglow'
}

var images = importAll(require.context('./img', false, /\.(gif|png|jpe?g|svg)$/));

export const Biglow = ({handleContactClick}) => {
    return (
        <div>
            <ProjectView projectDetails={projectDetails} images={images} handleContactClick={handleContactClick}/>
        </div>
    )
}