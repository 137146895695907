import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";

import './Header.css';

export const Header = ({handleContactClick}) => {
    const { pathname } = useLocation()
    const nicePathname = pathname.slice(1).toLocaleUpperCase()

    return (
        <header>
            <div>
            <Link to="/">
                <div>
                    <h1>David Bock</h1>
                    <h1>Graphics &amp; Design</h1>
                </div>
            </Link>
            </div>


            <div class="header-path">
                <Link to Header>
                    <h6>/{nicePathname || 'HOME'}</h6>
                </Link>
            </div>

            <div>
                <ul>
                    <li><Link to='/'>Work</Link></li>
                    <li><Link id="contact-header" onClick={handleContactClick}>Contact</Link></li>
                </ul>
                
            </div>

        </header>
    )
}