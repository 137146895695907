import React from 'react';
import { useState } from "react";

//import { Nav } from './components/Nav/Nav'
import { Header } from './components/Header/Header'
import { Footer } from './components/Footer/Footer'
import { Contact } from './components/Contact/Contact'


import { Home } from './pages/Home'
import { About } from './pages/About'
import { Extras } from './pages/Extras'


import { Biglow } from './pages/Biglow/Biglow'
import { Boreal } from './pages/Boreal/Boreal'
import { Primatehouse } from './pages/Primatehouse/Primatehouse'
import { Colabs } from './pages/Colabs/Colabs'
import { Oceans } from './pages/Oceans/Oceans'
import { Mitsubachi } from './pages/Mitsubachi/Mitsubachi'
import { Due } from './pages/Due/Due'
import { InclinedKids } from './pages/InclinedKids/InclinedKids'
// import { ZZT } from '../public/ZZT/ZZT'

import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';

function App() {

  const [isShowingModal, setisShowingModal] = useState(false)

  const handleContactClick = () => {
    setisShowingModal(!isShowingModal);
  }


  return (
    <div className="app">
      <Router>
      
      <ScrollToTop />

        <Header handleContactClick={handleContactClick} />
      
        <Switch>
          <Route path="/" exact={true}>
            <Home handleContactClick={handleContactClick}/>
          </Route>

          <Route path="/play/zzt" target="../zzt"/>


          <Route path="/work/biglow">
            <Biglow handleContactClick={handleContactClick}/>
          </Route>

          <Route path="/work/boreal">
            <Boreal handleContactClick={handleContactClick}/>
          </Route>


          <Route path="/work/due">
            <Due handleContactClick={handleContactClick}/>
          </Route>

          <Route path="/work/colabs">
            <Colabs handleContactClick={handleContactClick}/>
          </Route>

          <Route path="/work/oceans">
            <Oceans handleContactClick={handleContactClick}/>
          </Route>

          <Route path="/play/primatehouse">
            <Primatehouse handleContactClick={handleContactClick}/>
          </Route>

          <Route path="/work/inclinedkids">
            <InclinedKids handleContactClick={handleContactClick}/>
          </Route>

          <Route path="/play/mitsubachi">
            <Mitsubachi handleContactClick={handleContactClick}/>
          </Route>

          <Route path="/about/">
            <About handleContactClick={handleContactClick}/>
          </Route>

          <Route path="/extras/">
            <Extras handleContactClick={handleContactClick}/>
          </Route>

        </Switch>

        

        <Contact isShowingModal={isShowingModal} handleContactClick={handleContactClick}/>
        


      

        <Footer />
      </Router>
    </div>
  );
}

export default App;
