import React from 'react'
import { ProjectView } from '../../components/ProjectView'
import { importAll } from '../../components/ProjectView'

const projectDetails = {
    id: 'Oceans',
    name: 'Oceans North',
    link: 'https://www.duecucina.com',
    time: "2020",
    categories: ["publication", "logo design", "merchandise"],
    description: 
    [<p>Arctic ecological sentinels <a href="http://www.oceansnorth.ca">Oceans North</a> initiate scientific research and community partnership programs in Canada’s North.</p>,
    <p>I’ve been lucky to work with them on a variety of projects ranging from print publication and infographics to branding and merchandise. Next stop, Tuktoyaktuk!</p>
    ]
}

var images = importAll(require.context('./img', false, /\.(gif|png|jpe?g|svg)$/));

export const Oceans = ({handleContactClick}) => {
    return (
        <div>
            <ProjectView projectDetails={projectDetails} images={images} handleContactClick={handleContactClick}/>
        </div>
    )
}