import React from 'react'
import { ProjectView } from '../../components/ProjectView'
import { importAll } from '../../components/ProjectView'

const projectDetails = {
    id: 'Due',
    name: 'Dué Cucina Identity',
    link: 'https://www.duecucina.com',
    time: "2020",
    categories: ["branding", "logo design", "merchandise"],
    description: 
    [<p>Seattle-based fast casual startup <a href="https://www.duecucina.com">Dué Cucina</a> founder Davide Macchi asked me to help him refresh his visual identity and explore the evolution of his brand.</p>,
    
    <p>Due means ”two” in italian, and it's how many minutes long to cook fresh pasta til it's <em>al dente</em>. But Dué Cucina is also marked by dualities. Fine Italian cuisine, fast-casual American take-out. Due is beautiful &amp; delicious, and also quick ‘n’ tasty. I wanted to capture that in these brand expressions. The results are lively and full of flavour.</p>
    ]
}

var images = importAll(require.context('./img', false, /\.(gif|png|jpe?g|svg)$/));

export const Due = ({handleContactClick}) => {
    return (
        <div>
            <ProjectView projectDetails={projectDetails} images={images} handleContactClick={handleContactClick}/>
        </div>
    )
}