import React from 'react'
import { Link } from 'react-router-dom'

import './footer.css';

export const Footer = () => {
    return (

        <footer>
            <div>
                <p>&copy; {(new Date().getFullYear())} <a href="mailto:davebock@gmail.com">David Bock.</a></p>
            </div>
            <div>
                <ul>
                    <li><Link to=".">Home</Link> </li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/extras">Extras</Link></li>
                </ul>
            </div>
            <div>
                <ul className='footer-social'>
                    <li><a href="http://www.instagram.com/d2bock"><i class="fa fa-instagram" aria-hidden="true"></i> </a></li>
                    <li><a href="https://dribbble.com/davidbock"><i class="fa fa-dribbble" aria-hidden="true"></i> </a></li>
                    <li><a href="http://dave2kb.tumblr.com"><i class="fa fa-tumblr" aria-hidden="true"></i> </a></li>
                    <li><a href="http://www.linkedin.com/in/dkbock/"><i class="fa fa-linkedin" aria-hidden="true"></i> </a></li>
                </ul>
            </div>
        </footer>

    )
}