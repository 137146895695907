import React from 'react'
import { Link } from 'react-router-dom'
import './About.css'
import '../components/Nav/Nav.css';

export const Extras = ({handleContactClick}) => {
    return (
        <div className="">
            <nav>
            <ol>
                <Link to="/play/mitsubachi"><li>Mitsubachi Notebooks
                <div><img src={require ('../pages/Mitsubachi/img/Mitsubachi Notebooks.jpg')}alt=""/></div>
                </li></Link>

                <Link to="/work/biglow"><li>Biglow Woodcraft
                <div><img src={require ('../pages/Biglow/img/biglow-woodcraft.jpg')} alt="" /></div>
                </li></Link>

                <Link to="/play/primatehouse"><li>Primate House
                <div><img src={require ('../pages/Primatehouse/img/Primate-House.jpg')}alt=""/></div>
                </li></Link>
                
                <a href="../ZZT"><li>ZZT
                <div><img src={require ('../pages/Primatehouse/img/Primate-House.jpg')}alt=""/></div>
                </li></a>
            </ol>
            </nav>
        </div>
        
    )
}