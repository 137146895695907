import React from 'react'
import { Link } from "react-router-dom";

import './Nav.css';
  
export const Nav = ({handleContactClick}) => {
    return (
        <nav>
            <button>Work</button>
            <ol>
                
                <Link to="/work/colabs"><li>Co.Labs
                <div><img src={require ('../../pages/Colabs/img/colabs-portfolio_04.jpg')}alt=""/></div>
                    </li></Link>

                <Link to="/work/due"><li>Dué Cucina
                <div><img src={require ('../../pages/Due/img/due-port-logogif.gif' )}alt=""/></div>
                    </li></Link>

                <Link to="/work/boreal"><li>Boreal Besties
                <div><img src={require ('../../pages/Boreal/img/Boreal-Besties.jpg')}alt=""/></div>
                    </li></Link>

                <Link to="/work/oceans"><li>Oceans North
                <div><img src={require ('../../pages/Oceans/img/Oceans-hat.jpg')}alt=""/></div>
                    </li></Link>

                <Link to="/work/inclinedkids"><li>Inclined Kids
                <div><img src={require ('../../pages/InclinedKids/img/Inclined-Kids7.gif')}alt=""/></div>
                    </li></Link>

                {/* <Link to="/play/primatehouse"><li>Primate House
                <div><img src={require ('../../pages/Primatehouse/img/Primate-House.jpg')}alt=""/></div>
                </li></Link> */}

                {/* <Link to="/work/biglow"><li>
                    Biglow Woodcraft
                <div><img src={require ('../../pages/Biglow/img/biglow-woodcraft.jpg')} alt="" /></div>
                </li></Link> */}
                

                {/* <Link to="/play/mitsubachi"><li>Mitsubachi Notebooks
                <div><img src={require ('../../pages/Mitsubachi/img/Mitsubachi Notebooks.jpg')}alt=""/></div>
                    </li></Link> */}
                
            </ol>
            <button onClick={handleContactClick}>Contact</button>
        </nav>
    )
}