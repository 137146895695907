import React from 'react'
import { ProjectView } from '../../components/ProjectView'
import { importAll } from '../../components/ProjectView'

const projectDetails = {
    name: 'Primate House Archives',
    time: "2022",
    categories: ["design remix", "archival", "ephemeral"],
    description: 
    [   <p>Originating from a desire to preserve and celebrate vintage graphic design which continues to vanish all around us, Primate House Archive puts pop culture into context through contrast.</p>,
    <p>Juxtaposing yesteryear's discarded ephemera-pulp novels, button pins, postcards, and beer coasters with modern issues, obsessions, and distractions, the Archive has a little of everything and something for everyone.</p>,
    <p>Ask yourself; if culture is a cage don't you live in a Primate House too? Check out the <a href="https://primatehouse.tumblr.com/">Archives</a> to delve deeper.</p>
    ],
    images: '../img/Primatehouse'
}

var images = importAll(require.context('./img', false, /\.(png|jpe?g|svg)$/));

export const Primatehouse = () => {
    return (
        <div>
            <ProjectView projectDetails={projectDetails} images={images} />        
            
        </div>
    )
}