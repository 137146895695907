import React from 'react'
import { ProjectView } from '../../components/ProjectView'
import { importAll } from '../../components/ProjectView'

const projectDetails = {
    id: 'Colabs',
    name: 'Co.Labs Rebrand',
    link: 'https://www.co-labs.ca',
    time: "2020",
    categories: ["visual identity", "logo design", "branding", "conceptual"],
    description: [
        <p>Saskatoon tech startup incubator <a href="https://www.co-labs.ca">Co.labs</a> approached me to rethink their visual identity. The four sub-programs ("labs") needed to be brought in line. This presented a unique opportunity to create a robust graphic system.</p>,
        <p>Two directions were developed. Both are rooted in simple geometry, a vibrant palette, and a typographic logo system uniting the 5 unique workmarks.</p>
    ]
}

var images = importAll(require.context('./img', false, /\.(gif|png|jpe?g|svg)$/));


export const Colabs = ({handleContactClick}) => {
    return (
        <div>
            <ProjectView projectDetails={projectDetails} images={images} handleContactClick={handleContactClick}/>
        </div>
    )
}