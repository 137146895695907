import React from 'react'
//import { Link } from 'react-router-dom'
import './About.css';

export const About = ({handleContactClick}) => {
    return (
        <div className="container about-bar">
            <div>
                
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344 344">

                    <g id="cheeks" class="cheeks">
                        <circle cx="59.6" cy="200.6" r="31.4"/>
                        <circle cx="279.9" cy="200.6" r="31.4"/>
                    </g>
                    <g id="Layer_1">
                        <path class="st1" d="M302.5 41.9c0 28.8-23.3 52.2-52.2 52.2M232.5 41.9c0 28.8-23.3 52.2-52.2 52.2M158.5 41.9c0 28.8-23.3 52.2-52.2 52.2M91.9 41.9c0 28.8-23.3 52.2-52.2 52.2"/>
                        <circle class="st1" cx="99.9" cy="145.5" r="33.2"/>
                        <circle cx="240.2" cy="145.5" fill="none" stroke="#000" stroke-width="4" stroke-miterlimit="10" r="33.2"/>
                        <path class="st1" d="M239.8 212c0 39.7-32.2 71.8-71.8 71.8S96.2 251.7 96.2 212M266.1 201.7l20.6 20.5M252.8 227.4l20.5 20.5M243 194.4l20.6 20.6M286.3 191.4l20.5 20.6M210 191.4l20.6 20.6M186.5 189l20.5 20.5M175.9 201.7l20.6 20.5M101.7 190.3l-20.6 20.6M31.9 214.9l20.5 20.5M63.2 237.6l20.6 20.6M49.3 266.7l20.6 20.6M72.7 266.7l20.6 20.6M85.9 299l20.5 20.6M109.4 283.8l20.6 20.6M155.4 291.6l20.5 20.5M129.8 312.8l20.5 20.6M155.4 314.9l20.5 20.5M182.4 291.6l20.5 20.5M213.7 299l20.5 20.6M216.7 277l20.5 20.5M243 279.8l20.6 20.5M269.6 257l20.5 20.5M238.6 253.6l20.6 20.5M284.3 237.6l20.5 20.6M294.5 218.1l20.6 20.6M165.7 193.3l-20.6 20.6M129.3 184.2l-20.5 20.5M139.6 199.9L119 220.4M25.4 233.8L46 254.3M57.8 208.4L78.4 229"/>
                    </g>
                    <g id="pupils">
                        <circle cx="91" cy="145.5" r="24.2"/>
                        <circle cx="231.2" cy="145.5" r="24.2"/>
                    </g>
                </svg>

            </div>
            <div>
                <h1>About Me</h1>
                    <p>I am David Bock, independent graphic designer. I live and work in sunny Winnipeg, Manitoba.</p>
                    <p>I have experience branding, copywriting, illustration, video editing, print production, social media design… and I'm game to learn just about anything.</p>
                    <button onClick={handleContactClick}>Drop me a line!</button>
            </div>
        </div>
    )
}