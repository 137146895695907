import React from 'react'
import { ProjectView } from '../../components/ProjectView'
import { importAll } from '../../components/ProjectView'

const projectDetails = {
    name: 'Boreal Besties water safety colouring book',
    time: "2020",
    categories: ["illustration", "character design", "puzzle design"],
    description: 
    [   <p>Water safety education was impacted by the COVID-19 pandemic just like many other in person activities; so <a href="https://lifesaving.mb.ca/">Lifesaving Society of Manitoba</a> sought to find a way to engage youngsters in northern and rural communities with facts about water safety preparedness with an edutaining activity booklet.</p>,
    <p>Working alongside LSS, I devised a group of woodland characters and traditional activity book puzzles with a focus on water safety. The next year, I collaborated with the same team to create the winter version.</p>,
    <p>The final colouring and activity booklets are a lighthearted take on serious safety messaging that carries real relevance for many Manitobans especially in rural communities.</p>
    ],
    images: '../img/Boreal'
}

var images = importAll(require.context('./img', false, /\.(png|jpe?g|svg)$/));

export const Boreal = () => {
    return (
        <div>
            <ProjectView projectDetails={projectDetails} images={images} />        
            
        </div>
    )
}