import React from 'react'
import './Project.css';
import {Nav} from './Nav/Nav'


export function importAll(r) {
    return r.keys().map(r);
}

export const ProjectView = ({projectDetails, images, handleContactClick}) => {
    return (
        <div>
            {/* <div className="project-hero">
                <img src={images[0]}  alt=""></img>
            </div> */}
            <div className="project-description">
                <div>
                    <h1>
                        {projectDetails.name}
                    </h1>
                    <ul>
                        {projectDetails.categories.map((category)=> <li>{category}</li>)}
                    </ul>

                </div>

                <div>
                    
                    {projectDetails.description}
                </div>
            </div>

            <div className="project-images">
                <div>
                    {images.map((image, index) =>    <img key={index} src={image} alt=""></img>)}
                </div>
            </div>

            <Nav handleContactClick={handleContactClick} />

        </div>
    )
}