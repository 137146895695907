import React from 'react'
import { ProjectView } from '../../components/ProjectView'
import { importAll } from '../../components/ProjectView'

const projectDetails = {
    id: 'InclinedKids',
    name: 'Inclined Kids',
    link: 'https://www.inclinedkids.ca',
    time: "2020",
    categories: ["branding", "illustration", "motion graphics"],
    description: 
    [<p>Matthew Cline's <a href="https://www.inclinedkids.ca">Inclined Kids</a> is a fledgling non-profit devised to reach Winnipeg's underserved youth with opportunities to skate at local workshops, and raising funds to also provide those kids with the gear to skate.</p>,
    <p>In collaboration with <a href="http://www.nataliekilimnik.com">Natalie</a>, we created a flexible and lightweight toolkit to allow us to create playful, spontaneous collateral for fundraisers, online streaming announcements, and micro-campaigns.</p>,
    <p>I created Kid Radish, the radical skateroot to promote the InclinedKids gaming stream as a throwback to 90s video game mascots, as well as some animations and good ol' fashioned graphics to round out social media needs.
    </p>
    ]
}

var images = importAll(require.context('./img', false, /\.(gif|png|jpe?g|svg)$/));

export const InclinedKids = ({handleContactClick}) => {
    return (
        <div>
            <ProjectView projectDetails={projectDetails} images={images} handleContactClick={handleContactClick}/>
        </div>
    )
}