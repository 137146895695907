import React from 'react'
import { ProjectView } from '../../components/ProjectView'
import { importAll } from '../../components/ProjectView'

const projectDetails = {
    name: 'Mitsubachi Notebooks',
    time: "2018-2020",
    categories: ["product", "brand development", "日本語"],
    description: [
        <p>Fancy art notebooks are too refined for the most informal doodling and scattered list-making; what I needed was a friendly substrate for taking my first baby-steps writing Japanese kana.</p>,
        <p> So I created a new kind of scribbler: Inspired by kana workbooks and the utilitarian <em>cahier</em>, and hand-bound with love. Mitsubachis are notebooks which embolden one to take note.</p>,
        <p>I make these, and you can buy em, you know？ <a href="mailto:davebock@gmail.com?subject=Mitsubachi Notebooks&body=I would like to order some notebooks!">Contact</a> for details. みんなさん、かきましょう！</p> 
    ]
    
}

var images = importAll(require.context('./img', false, /\.(gif|png|jpe?g|svg)$/));

export const Mitsubachi = () => {
    return (
        <div>
            <ProjectView projectDetails={projectDetails} images={images} />
        </div>
    )
}